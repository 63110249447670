<template>
  <v-container class="mt-4">
    <v-row v-if="displayTimes || times.length !== 0" align="center">
      <v-col cols="12" sm="8">
        <v-card>
          <!-- Times -->
          <data-table
            toolbar-title="TimeSheet"
            selected-store-attr="timesSelected"
            :headers="timeHeaders"
            :items="times"
            :show-totals="true"
            project-attr="Times"
          ></data-table>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <!-- Create New Form -->
        <punch />
      </v-col>
    </v-row>

    <v-row v-else align="center" justify="center">
      <v-btn color="primary" @click="displayTimes = true">Display Times</v-btn>
    </v-row>

    <v-row v-if="displayTodos || todos.length !== 0" align="center">
      <v-col cols="12" sm="8">
        <v-card>
          <!-- Todos -->
          <data-table
            toolbar-title="Todos"
            selected-store-attr="todosSelected"
            :headers="todoHeaders"
            :items="todos"
            project-attr="Todos"
          >
          </data-table>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <!-- Create New Form -->
        <create-todo />
      </v-col>
    </v-row>

    <v-row v-else align="center" justify="center">
      <v-btn color="primary" @click="displayTodos = true">Display Todos</v-btn>
    </v-row>
  </v-container>
</template>

<script>
/**
 * Show All Unfinished Times
 *
 * Add a new one, adds start and message but end == null
 * Unfinished Time is .where("end", "==", null)
 *
 */

import { projectRef } from "../firebase";

import { Punch, DataTable, CreateTodo } from "../components";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: { Punch, DataTable, CreateTodo },
  data: () => ({
    displayTimes: false,
    displayTodos: false,

    newTime: {},
    times: [],
    todos: [],

    timeHeaders: [
      { text: "Hours", value: "hours" },
      { text: "Day", value: "day" },
      { text: "Start", value: "start" },
      { text: "End", value: "end" },
      { text: "Description", value: "description" },
      { text: "Actions", value: "actions" },
    ],
    todoHeaders: [
      { text: "Completed", value: "completed" },
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "Actions", value: "actions" },
    ],
  }),
  computed: mapState(["timesSelected", "todosSelected"]),
  firestore() {
    return {
      times: projectRef(this.$store.state.projectId).collection("Times"),
      todos: projectRef(this.$store.state.projectId)
        .collection("Todos")
        .orderBy("created"),
    };
  },
};
</script>
